import React from 'react';
import styled from 'styled-components';
import PageSeo from '../components/Seo/PageSeo';
import PageTitle from '../components/PageTitle';
import PageSection from '../components/PageSection';
import { respondTo } from '../styles/shared/breakpoints';
import notFoundCopy from '../copy/404';
import Button from '../components/Button';

// SEO INFORMATION
const pageMeta = {
  title: 'Not Found | WELIREG® (belzutifan) for Health Care Professionals',
  description: 'The page you\'re looking for cannot be found. Please check back at a later date or visit other pages on Welireghcp.com.',
  schemaJsonLD: [
  ]
}

const PageSection404 = styled(PageSection)`
  padding-bottom: 0px;

  ${respondTo.sm`
    margin-left: 100px;
    padding-bottom: 0px;
  `}
`

const notFoundPage = () => {
  return (
    <>
      <PageSeo pageMeta={pageMeta} >
        <link rel="canonical" href="https://www.welireghcp.com/404/" />
      </PageSeo>
      <PageTitle unbranded>
        { notFoundCopy.pageTitle }
      </PageTitle>

      <PageSection404>
          { notFoundCopy.pageHeader }
      </PageSection404>
      <PageSection404>
          <Button variant='primaryBlue' to='/'>Home</Button>
      </PageSection404>
    </>
  );
};


export default notFoundPage;